<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<h5 class="mb-4">
							<strong>Operaciones - Resumen de gastos en efectivo</strong>
						</h5>
					</div>
					<div class="col-md-6 text-right">
						<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
					</div>
				</div>
				<div class="row pt20">
					<div class="col-md-4">
						<div class="card bg-warning border-0">
							<div class="card-body">
								<div class="text-white font-weight-bold">
									<div class="font-size-21 mb-2">Registros por validar</div>
									<div class="d-flex align-items-end flex-wrap">
										<div class="font-size-36 mb-n2">{{ toValidate }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="card bg-danger border-0">
							<div class="card-body">
								<div class="text-white font-weight-bold">
									<div class="font-size-21 mb-2">Efectivo autorizado</div>
									<div class="d-flex align-items-end flex-wrap">
										<div class="font-size-36 mb-n2">{{ numeral(cashFlowSumary.out).format('$0,0.00') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="card bg-success border-0">
							<div class="card-body">
								<div class="text-white font-weight-bold">
									<div class="font-size-21 mb-2">Efectivo disponible</div>
									<div class="d-flex align-items-end flex-wrap">
										<div class="font-size-36 mb-n2">{{ numeral(cashFlowSumary.in - cashFlowSumary.out).format('$0,0.00') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4">
						<b>Rango de Fecha </b>
						<a-icon type="reload" @click="onResetFilter('defaultDate')" :style="{ fontSize: '14px', color: 'red' }" />
						<a-range-picker style="width: 100%" @change="initModule" v-model="filters.defaultDate" :default-value="filters.defaultDate" :format="dateFormat" />
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<hr class="hrText" data-content="Resumen y transacciones del periodo" />
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<a-table :columns="columns" :dataSource="cashFlowList" rowKey="id">
							<div slot="id" slot-scope="record">
								{{ record.id }}<br />
								<a-tag color="green" v-if="record.type == 'in'">Ingreso</a-tag>
								<a-tag color="red" v-if="record.type == 'out'">
									<router-link :to="`/administration/purchase-orders/${record.purchase_order}`" target="_blank" v-if="record.purchase_order">Egreso ODC: {{ record.purchase_order }}</router-link>
									<span v-else>Egreso</span>
								</a-tag>
							</div>
							<div slot="creation_date" slot-scope="record">
								{{ moment(record.created_at).format('YYYY/MM/DD') }}
							</div>
							<div slot="amount" slot-scope="record">
								{{ numeral(record.amount).format('$0,0.00') }}
							</div>
							<div slot="details" slot-scope="record">
								<div v-if="record.type == 'out'">
									<a :href="`/#/administration/purchase-orders/${record.purchase_order_id}`" target="new"> ODC: {{ record.purchase_order_id }}</a>
									<div v-if="record.filename" @click="openReceipt(record.filename)" class="pointer">Comprobante</div>
								</div>
								<div v-else>Cortes #{{ record.cashflow_id }}</div>
							</div>
							<div slot="authReject" slot-scope="record">
								<div v-if="record.type == 'in'">-</div>
								<div v-else>
									<div v-if="record.status == 1">
										Autorizado por: {{ record.fullName }} <br />
										{{ moment(record.control_at).format('YYYY-MM-DD HH:mm:ss') }} hrs.
									</div>
									<div v-else-if="record.status == -1">
										Rechazado por: {{ record.fullName }} <br />
										{{ moment(record.control_at).format('YYYY-MM-DD HH:mm:ss') }} hrs.
									</div>
									<div v-else>- En espera -</div>
								</div>
							</div>
							<div slot="action" slot-scope="record">
								<div v-if="record.type == 'out' && record.status == 0">
									<a-tooltip placement="top" class="ml5">
										<template slot="title">
											<span>Validar</span>
										</template>
										<button class="btn btn-success ant-btn-sm ant-btn-icon-only" @click="controlCashflow(record.id, true)">
											<a-icon type="check-circle" />
										</button>
									</a-tooltip>
									<a-tooltip placement="top" class="ml5">
										<template slot="title">
											<span>Rechazar</span>
										</template>
										<button class="btn btn-danger ant-btn-sm ant-btn-icon-only" @click="onRestoreOrder(record.id, record.purchase_order_id)">
											<a-icon type="delete" />
										</button>
									</a-tooltip>
								</div>
							</div>
						</a-table>
					</div>
				</div>
			</div>
		</div>
		<a-modal :visible="modalReceipt.visible" width="500px" :footer="null" title="Comprobante de pago" :closable="true" @cancel="handleCancelReceipt">
			<paymentReceipt v-if="modalReceipt.visible" :receiptURL="modalReceipt.url" :editable="false" />
		</a-modal>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import numeral from 'numeral'
import paymentReceipt from '@/components/paymentReceipt'
import Swal from 'sweetalert2'

moment.tz.setDefault('America/Mexico_City')
const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'operationsCashflowView',
	components: {
		paymentReceipt,
	},
	computed: {
		...mapGetters('cashFlow', ['spinnerLoaderLabel', 'spinnerLoader', 'filters', 'cashFlowList', 'cashFlowSumary']),
		toValidate() {
			return this.cashFlowList.filter((e) => e.status == 0 && e.type == 'out').length
		},
	},
	data() {
		return {
			apiURL: process.env.VUE_APP_API_URL,
			dateFormat,
			columns: [
				{
					title: 'ID',
					align: 'center',
					scopedSlots: { customRender: 'id' },
				},
				{
					title: 'Fecha',
					align: 'center',
					scopedSlots: { customRender: 'creation_date' },
				},
				{
					title: 'Monto',
					scopedSlots: { customRender: 'amount' },
					align: 'center',
				},
				{
					title: 'Detalles',
					scopedSlots: { customRender: 'details' },
				},
				{
					title: 'Autorización / Rechazo',
					scopedSlots: { customRender: 'authReject' },
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '15%',
				},
			],
			modalReceipt: {
				visible: false,
				url: '',
			},
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		moment,
		numeral,
		initModule() {
			let payload = {
				startDate: moment(this.filters.defaultDate[0]).format(dateFormat),
				endDate: moment(this.filters.defaultDate[1]).format(dateFormat),
			}
			this.$store.dispatch('cashFlow/SPENDING_AUTHORIZATIONS', payload)
		},
		onResetFilter(key) {
			this.$store.dispatch('cashFlow/RESET_ONE_FILTER', key)
			this.initModule()
		},
		handleCancelReceipt() {
			this.modalReceipt.visible = false
		},
		openReceipt(payload) {
			this.modalReceipt = {
				visible: true,
				url: `${this.apiURL}/${payload.filename}`,
			}
		},
		controlCashflow(id, value) {
			value = !!value ? 1 : -1
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas validar la operación?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('cashFlow/SET_SPENDING_AUTHORIZATIONS', { id, value })
				}
			})
		},
		onRestoreOrder(id, orderID) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas rechazar y restaurar la ODC a su estado original?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					await this.$store.dispatch('purchaseOrders/RESTORE_ORDER', orderID)
					this.$store.dispatch('cashFlow/SET_SPENDING_AUTHORIZATIONS', { id, value: -1 })
				}
			})
		},
	},
}
</script>