<template>
	<div>
		<div class="row">
			<div class="col-md-12 text-center" v-if="!editable" style="padding-bottom: 20px">
				<a :href="receiptURL" target="new">
					<img class="img-fluid" :src="receiptURL" />
				</a>
			</div>
			<div class="col-md-12 text-center" v-else-if="!!receiptImage" style="padding-bottom: 20px">
				<a :href="receiptImage" target="new">
					<img class="img-fluid" :src="receiptImage" />
				</a>
			</div>
			<div class="col-md-12 text-center" v-else>- No se ha añadido un comprobante -</div>
			<div class="col-md-12 text-right" v-if="editable">
				<a-upload name="file" :multiple="false" :remove="handleRemove" :before-upload="beforeUpload" :showUploadList="false" accept="image/*" @change="onChangeUpload">
					<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
				</a-upload>
				<button class="btn btn-info ml5" v-if="!!receiptImage && fileList.length" @click="handleUpload">Subir comprobante</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from 'store'
import axios from 'axios'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'

export default {
	name: 'paymentReceipt',
	props: {
		orderId: {
			type: Number,
			default: null,
		},
		editable: {
			type: Boolean,
			default: true,
		},
		receiptURL: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapGetters('purchaseOrders', ['actualPurchaseOrder']),
		receiptData() {
			let response = {}
			if (utilities.objectValidate(this.actualPurchaseOrder, 'receipt', false)) {
				response = JSON.parse(this.actualPurchaseOrder.receipt)[0]
			}
			return response
		},
	},
	data() {
		return {
			fileList: [],
			fileChanged: false,
			receiptImage: '',
			apiURL: process.env.VUE_APP_API_URL,
			uploadHeaders: {},
		}
	},
	beforeMount() {
		if (utilities.objectValidate(this.receiptData, 'filename', false)) {
			this.receiptImage = `${this.apiURL}/${this.receiptData.filename}`
		}
	},
	mounted() {
		const accessToken = store.get('accessToken')
		this.uploadHeaders = {
			Authorization: `Bearer ${accessToken}`,
		}
	},
	methods: {
		cleanData() {
			this.fileList = []
			this.fileChanged = false
			this.receiptImage = ''
		},
		handleRemove() {
			this.fileList = []
		},
		beforeUpload(file) {
			this.fileChanged = true
			this.fileList = [file]
			return false
		},
		handleUpload() {
			this.$store.commit('purchaseOrders/SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando archivo...',
			})
			const { fileList } = this
			const formData = new FormData()

			formData.append('file', fileList[0])
			formData.append('orderId', this.orderId)
			formData.append('fileType', 'receipt')

			if (utilities.objectValidate(this.receiptData, 'id', false)) {
				formData.append('id', this.receiptData.id)

				axios
					.put(`${this.apiURL}/api/purchase-orders/file`, formData, { headers: this.uploadHeaders })
					.then((response) => {
						this.$store.commit('purchaseOrders/SET_STATE', {
							spinnerLoader: false,
						})

						this.fileList = []
						Swal.fire({
							title: 'Ordenes de compra',
							text: response.message || 'Datos actualizados con éxito',
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							this.$emit('success')
						})
					})
					.catch((error) => {
						this.$store.commit('purchaseOrders/SET_STATE', {
							spinnerLoader: false,
						})

						Swal.fire({
							title: 'Ordenes de compra',
							text: 'Error al procesar el archivo adjunto',
							icon: 'error',
							confirmButtonText: 'Ok',
						})
					})
			} else {
				axios
					.post(`${this.apiURL}/api/purchase-orders/file`, formData, { headers: this.uploadHeaders })
					.then((response) => {
						this.$store.commit('purchaseOrders/SET_STATE', {
							spinnerLoader: false,
						})

						this.fileList = []
						Swal.fire({
							title: 'Ordenes de compra',
							text: response.message || 'Datos actualizados con éxito',
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							this.$emit('success')
						})
					})
					.catch((error) => {
						this.$store.commit('purchaseOrders/SET_STATE', {
							spinnerLoader: false,
						})

						Swal.fire({
							title: 'Ordenes de compra',
							text: 'Error al procesar el archivo adjunto',
							icon: 'error',
							confirmButtonText: 'Ok',
						})
					})
			}
		},
		onChangeUpload(event) {
			const reader = new FileReader()
			reader.readAsDataURL(event.file)
			reader.onload = () => {
				this.receiptImage = reader.result
			}
		},
	},
}
</script>